// Footer.tsx
import { useQueryClient } from "@tanstack/react-query";
import { usePlayerContext } from "context/PlayerContext";
import Hls from "hls.js"; // Import HLS.js library
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUserStore } from "store";
import { doesSessionExist } from "supertokens-web-js/recipe/session";
import placeholder from "../../assets/images/stagedive-logo.png";
import { ApiContext } from "../../context";
import { useErrorHandler } from "../../context/ErrorHandler";
import { usePlaylistContext } from "../../context/NewPlaylistContext";
import LikeButton from "../LikeButton";
import PlaylistModal from "../PlaylistModal";
import "./MusicPlayerFooter.css";
import { useMediaSession } from "./useMediaSession";

export default function MusicPlayerFooter() {
  const stagedive = useContext(ApiContext);
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();
  const { user } = useUserStore();

  const {
    playlistData,
    setPlaylistData,
    currentSongIndex,
    setCurrentSongIndex,
  } = usePlaylistContext();

  const {
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    isHLSInitialized,
    setIsHLSInitialized,
    volume,
    setVolume,
    videoRef,
    canStream,
    setIsLiked,
    isLiked,
    setLikeForTrack,
    isPlaying,
    setIsPlaying,
    autoplayNext,
    setAutoplayNext,
    isLoading,
    setIsLoading,
  } = usePlayerContext();

  const [showPlaylistModal, setShowPlaylistModal] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [isRepeatOn, setIsRepeatOn] = useState(false);

  //Variable used to track time for analytics
  let timeListened = 0;

  const currentSong = {
    albumName: playlistData[currentSongIndex]?.album?.name,
    albumArt:
      playlistData[currentSongIndex]?.album?.coverImageUrl ?? placeholder, // Album art url
    id: playlistData[currentSongIndex]?.id,
    title: playlistData[currentSongIndex]?.name, // Song Title
    artist:
      playlistData[currentSongIndex]?.creators?.[0]?.name ?? "Unknown Artist", // Artist Name
    artistID: playlistData[currentSongIndex]?.creators?.[0]?.id ?? "Unknown ID", //Artist ID
  };

  const toggleRepeat = () => {
    setIsRepeatOn(!isRepeatOn);
  };

  const handleNextSong = () => {
    if (canStream) {
      setCurrentTime(0); // Reset currentTime to 0
      setCurrentSongIndex((songIndex) => (songIndex + 1) % playlistData.length);
      setAutoplayNext(true); // Enable autoplay for next song
      setLikeForTrack(playlistData[currentSongIndex + 1]);
    }
  };

  const handlePreviousSong = () => {
    if (canStream) {
      setCurrentTime(0); // Reset currentTime to 0
      setCurrentSongIndex((songIndex) =>
        songIndex === 0 ? playlistData.length - 1 : songIndex - 1
      );
      setAutoplayNext(true);
      setLikeForTrack(playlistData[currentSongIndex - 1]);
    }
  };

  //Function to send track listen event to the API
  const sendTrackListenEvent = async (timeListened: number) => {
    if (playlistData[currentSongIndex] && timeListened >= 10) {
      try {
        const eventData = {
          event: "track_listen",
          properties: {
            trackId: playlistData[currentSongIndex].id,
            creatorIds: [playlistData[currentSongIndex].creators[0].id],
            seconds: timeListened,
          },
        };

        if (await doesSessionExist()) {
          await stagedive.createEvent(eventData);
        }
      } catch (error) {
        handleError(error);
      }
      //Reset after sending, changed from 0 to keep remainder after sending
      //timeListened = 0;
      //console.log("sent request and timelistened is now " + timeListened + " Creator Name " + [playlistData[currentSongIndex].creators[0].name]);
      timeListened -= Math.floor(timeListened);
    }
  };

  useMediaSession(handlePreviousSong, handleNextSong, currentSong, videoRef);

  //Event listener to the audio tag to track time spent listening
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.ontimeupdate = () => {
        if (videoRef.current) {
          setCurrentTime(videoRef.current.currentTime);
        }
      };
      let intervalId: NodeJS.Timeout;
      const startInterval = () => {
        intervalId = setInterval(() => {
          // Check if the audio is playing and not seeking
          if (isPlaying && !isSeeking) {
            timeListened += 1;
            if (timeListened >= 10) {
              sendTrackListenEvent(timeListened);
              timeListened = 0;
            }
          }
        }, 1000);
      };

      // Start the interval when the audio starts playing
      if (isPlaying && !isSeeking) {
        startInterval();
      }

      // Stop the interval when the component is unmounted
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isPlaying, playlistData, currentSongIndex, isSeeking]);

  // If a track is being started, record a 'play' count
  const lastPlayedTrackId = useRef<null | string>(null);
  useEffect(() => {
    //Function to send track play event to the API
    const sendTrackPlayEvent = async () => {
      if (!playlistData[currentSongIndex]) return;

      const eventData = {
        event: "track_played",
        properties: {
          trackId: playlistData[currentSongIndex].id,
          creatorIds: [playlistData[currentSongIndex].creators[0].id],
        },
      };
      try {
        const response = await stagedive.createEvent(eventData);
      } catch (error) {
        console.error("Error posting event:", error);
      }
    };

    if (videoRef.current) {
      videoRef.current.onplay = () => {
        if (
          typeof videoRef.current?.currentTime === "number" &&
          isFinite(videoRef.current?.currentTime)
        ) {
          if (videoRef.current?.currentTime < 0.01) {
            const currentTrack = playlistData[currentSongIndex];

            // Don't send multiple when clicking play/pause on current track
            if (lastPlayedTrackId.current !== currentTrack.id) {
              sendTrackPlayEvent();
            }
            // Update the last played track ID
            lastPlayedTrackId.current = currentTrack.id;
          }
        }
        setIsPlaying(true);
      };
    }
  }, [
    autoplayNext,
    playlistData,
    currentSongIndex,
    videoRef,
    setIsPlaying,
    stagedive,
  ]);

  //Checks to see if the hls is initialized then loads current song.
  useEffect(() => {
    if (!isHLSInitialized) {
      loadCurrentSong();
    }

    setLikeForTrack(playlistData[currentSongIndex]);
  }, [isHLSInitialized, currentSongIndex]);

  useEffect(() => {
    const getNewReleases = async () => {
      try {
        //Check if playlistData is empty, if it is, then set it to New Releases
        if (playlistData.length == 0) {
          const playlists = await stagedive.getLatestTracks(30);
          setPlaylistData(playlists);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getNewReleases();
  }, [setIsHLSInitialized, setPlaylistData, stagedive]);

  // First Load Effect
  useEffect(() => {
    if (videoRef.current) {
      // Initialize Volume to our default value
      videoRef.current.volume = volume;
      //Add the time if it is set in localstorage
      if (currentTime > 0) {
        videoRef.current.currentTime = currentTime;
      }
      // Assign onplay/onpause event listeners
      videoRef.current.onplay = async function () {
        if ((await doesSessionExist()) && !canStream) {
          // authenticated but not subscribed
          videoRef.current?.pause();
          alert(
            "Please visit your Account page to SUBSCRIBE to StageDive and start streaming!"
          );
        } else {
          setIsPlaying(true);
        }
        setIsPlaying(true);
      };
      videoRef.current.onpause = () => setIsPlaying(false);
      videoRef.current.onloadedmetadata = () => {
        setDuration(videoRef.current ? videoRef.current.duration : 0);
      };
    }
  }, []);

  // Set up the 'onended' event handler with the correct playlist length
  useEffect(() => {
    if (playlistData.length > 0) {
      if (videoRef.current) {
        videoRef.current.onended = () =>
          handleSongEnded(playlistData.length, isRepeatOn);
      }
    }
  }, [playlistData, isRepeatOn]);

  // Play song
  useEffect(() => {
    if (videoRef.current && autoplayNext && canStream) {
      videoRef.current.muted = true;
      videoRef.current
        .play()
        .then(() => {
          if (videoRef.current) videoRef.current.muted = false;
        })
        .catch((error) => {
          console.error("Autoplay failed:", error);
        });
      setAutoplayNext(false);
    }
  }, [autoplayNext]);

  // Set timestamp on mediaSession
  const loadCurrentSong = () => {
    const currentSongUrl = playlistData[currentSongIndex]?.url;
    if (currentSongUrl) {
      setIsLoading(true);

      if (Hls.isSupported() && videoRef.current) {
        const hls = new Hls();
        hls.config.xhrSetup = function (xhr, url) {
          // Include the 'Credentials' option to enable sending cookies
          xhr.withCredentials = true;
        };
        hls.loadSource(currentSongUrl);
        hls.attachMedia(videoRef.current);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          setIsLoading(false);
          if (!isPlaying && autoplayNext) {
            setAutoplayNext(true);
          }
        });
      } else if (
        videoRef.current?.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = currentSongUrl;
        setIsLoading(false);
        // Autoplay or start playback here if needed
        if (autoplayNext) {
          videoRef.current.play().catch((error) => {
            console.error("Autoplay failed:", error);
          });
        }
      }
    }
  };

  const handleSongEnded = (playlistLength, isRepeatOn) => {
    if (isRepeatOn) {
      setCurrentTime(0); //Reset currentTime to 0 to repeat the current song
      setIsRepeatOn(false); //Turn off repeat after repeating once
      setAutoplayNext(true);
    } else {
      setCurrentTime(0);
      setCurrentSongIndex((songIndex) => (songIndex + 1) % playlistLength);
      setAutoplayNext(true);
      setLikeForTrack(playlistData[currentSongIndex + 1]);
    }
  };

  const handlePlayPause = async () => {
    setIsPlaying(!isPlaying);
    if (isPlaying) {
      videoRef.current?.pause();
    } else {
      if (videoRef.current && !autoplayNext) {
        setAutoplayNext(true);
      }
      videoRef.current?.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
    if ((await doesSessionExist()) && !canStream) {
      alert(
        "To stream, please visit your Account page and Subscribe to StageDive."
      );
    }
  };

  const handleSeek = (e) => {
    if (canStream) {
      setIsSeeking(true);
      const newTime = parseFloat(e.target.value);
      setCurrentTime(newTime);
      if (videoRef.current) {
        videoRef.current.currentTime = newTime;
      }
      setIsSeeking(false);
    }
  };

  const seekTime = (time: number) => {
    if (canStream && videoRef.current) {
      const newTime = Math.min(
        Math.max(currentTime + time, 0),
        videoRef.current.duration
      );
      setCurrentTime(newTime);
      videoRef.current.currentTime = newTime;
    }
  };

  //Can potentially cache user's volume, set as default
  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const incrementVolume = () => {
    const newVolume = Math.min(volume + 0.1, 1); //Cap at 1 (100%)
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const decrementVolume = () => {
    const newVolume = Math.max(volume - 0.1, 0); //Minimum at 0 (mute)
    setVolume(newVolume);
    if (videoRef.current) {
      videoRef.current.volume = newVolume;
    }
  };

  const handleLikeTrack = async (liked: boolean) => {
    const trackId = playlistData[currentSongIndex].id;
    try {
      if (liked) {
        await stagedive.likeTrack(trackId);
        setIsLiked(true);
      } else {
        await stagedive.unLikeTrack(trackId);
        setIsLiked(false);
      }
      queryClient.invalidateQueries({
        queryKey: ["getUserLikedTracks", (user as CurrentUser).id],
      });
    } catch (err) {
      handleError(err);
    }
  };

  //Add keyboard shortcuts to the footer
  useEffect(() => {
    const isFormElement = (element: Element | null): boolean => {
      if (!element) return false;
      const tagName = element.tagName.toLowerCase();
      return (
        tagName === "input" ||
        tagName === "textarea" ||
        tagName === "select" ||
        element.getAttribute("contenteditable") === "true"
      );
    };

    // Check if user is typing to prevent unintentional shortcute triggers
    if (isFormElement(document.activeElement)) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case " ":
          if (event.shiftKey) {
            event.preventDefault(); //Prevent default space scroll behavior
            handlePlayPause();
          }
          break;
        case "ArrowRight":
          if (event.shiftKey) {
            seekTime(10); //Seek forward 10 seconds
          } else {
            handleNextSong();
          }
          break;
        case "ArrowLeft":
          if (event.shiftKey) {
            seekTime(-10); //Seek backward 10 seconds
          } else {
            handlePreviousSong();
          }
          break;
        case "ArrowUp":
          event.preventDefault();
          incrementVolume();
          break;
        case "ArrowDown":
          event.preventDefault();
          decrementVolume();
          break;
        case "R":
          if (event.shiftKey) {
            toggleRepeat();
          }
          break;
        case "L":
          if (event.shiftKey) {
            handleLikeTrack(!isLiked);
          }
          break;
        case "P":
          if (event.shiftKey) {
            setShowPlaylistModal(!showPlaylistModal);
          }
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    handlePlayPause,
    handleNextSong,
    handlePreviousSong,
    incrementVolume,
    decrementVolume,
    toggleRepeat,
    handleLikeTrack,
    isLiked,
    setShowPlaylistModal,
    showPlaylistModal,
  ]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <div className="h-[200px] w-full"></div>
      <div className="fixed bottom-0 md:h-[160px] left-0 right-0 flex flex-col gap-5 md:flex-row items-center bg-zinc-900 text-primary p-2.5 md:p-5 shadow-[0_-2px_10px_rgba(0,0,0,0.5)] w-full mt-4">
        {/* Left side content */}
        <div className="flex items-center md:w-3/12">
          <img
            src={currentSong.albumArt}
            alt="Album Art"
            className="object-contain bg-black w-16 h-16 md:w-20 md:h-20 m-2.5"
          />
          <div className="flex flex-col justify-between">
            <Link to={`/track/${currentSong.id}`}>
              <p className="text-base font-bold md:text-lg">
                {currentSong.title}
              </p>
            </Link>
            <Link to={`/creator/${currentSong.artistID}`}>
              <p className="text-sm md:text-base">{currentSong.artist}</p>
            </Link>
          </div>
        </div>
        {/* Center content */}
        <div className="flex flex-col items-center pb-5 w-[70%] text-primary">
          <div className="flex justify-center items-center w-full">
            <a
              className="tooltip tooltip-left lg:tooltip-bottom"
              data-tip="Add current track to playlist"
              onClick={() => {
                setShowPlaylistModal(!showPlaylistModal);
              }}
            >
              <span className="material-symbols-rounded">playlist_add</span>
            </a>
            <span
              onClick={handlePreviousSong}
              className="material-symbols-rounded"
            >
              skip_previous
            </span>
            <span
              onClick={handlePlayPause}
              className="material-symbols-rounded text-5xl"
            >
              {isPlaying ? "pause" : "play_arrow"}
            </span>
            <span onClick={handleNextSong} className="material-symbols-rounded">
              skip_next
            </span>
            <span
              onClick={toggleRepeat}
              className={`material-symbols-rounded cursor-pointer ${isRepeatOn ? "text-blue-500" : ""}`}
            >
              repeat
            </span>
          </div>
          <div className="flex flex-col items-center w-[90%]">
            <div>{formatTime(currentTime)}</div>
            <input
              type="range"
              min="0"
              max={duration}
              step="0.1"
              value={currentTime}
              onChange={handleSeek}
            />
            <div>{formatTime(duration)}</div>
          </div>
        </div>
        {/* Right side content */}
        <div className="flex flex-col-reverse items-center absolute top-[10%] right-5 rounded-[var(--curved)] h-[170px] pb-0.5 sm:flex-row sm:relative sm:right-auto sm:bottom-auto sm:gap-[5px] sm:h-auto sm:p-0 sm:bg-transparent justify-around sm:justify-center md:justify-end text-primary">
          <div
            className="mb-2 mx-1 tooltip tooltip-left lg:tooltip-bottom"
            data-tip="Like track"
          >
            <LikeButton liked={isLiked} onClick={handleLikeTrack} />
          </div>

          <span className="material-symbols-rounded volume-label hidden sm:block">
            volume_up
          </span>
          <input
            className="volume-bar hidden sm:block"
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
          />
        </div>
        <audio ref={videoRef} style={{ display: "none" }} controls />
        <PlaylistModal
          open={showPlaylistModal}
          handleToggle={() => {
            setShowPlaylistModal(!showPlaylistModal);
          }}
          track={playlistData[currentSongIndex]}
        ></PlaylistModal>
      </div>
    </>
  );
}
