import * as Sentry from "@sentry/browser";

class StageDiveAPI {
  baseURL: string;

  constructor() {
    this.baseURL =
      process.env.REACT_APP_STAGEDIVE_API_URL ||
      "https://api.dev.stagedive.com";
  }

  async handleFetch(...args: Parameters<typeof fetch>) {
    const [input, options] = args;
    const response = await fetch(...args);
    if (!response.ok && response.status != 404) {
      if (process.env.REACT_APP_NODE_ENV === "production") {
        const responseText = await response.text();
        Sentry.captureException(responseText, {
          extra: { response: responseText, options },
          tags: { section: "api" },
        });
      }
      throw response;
    } else if (response.status == 204 || response.status == 404) {
      return response;
    } else {
      return response.json();
    }
  }

  async me(): Promise<CurrentUser> {
    const endpoint = `${this.baseURL}/v1/me`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async createCheckoutSession(
    priceId: string,
    uiMode: string = "hosted"
  ): Promise<PaymentURLRedirect> {
    const endpoint = `${this.baseURL}/v1/payments/stripe/create-checkout-session?priceId=${priceId}&uiMode=${uiMode}`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async createCustomerPortalSession(customerId): Promise<PaymentURLRedirect> {
    const endpoint = `${this.baseURL}/v1/payments/stripe/create-customer-portal-session?customerId=${customerId}`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async createStripeAccountLink(country: string): Promise<PaymentURLRedirect> {
    const endpoint = `${this.baseURL}/v1/payments/stripe/onboard-user?country=${country}`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async createRefreshStripeAccountLink(): Promise<PaymentURLRedirect> {
    const endpoint = `${this.baseURL}/v1/payments/stripe/onboard-user/refresh`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async listStripeProducts(): Promise<ListStripeProductsResponse> {
    const endpoint = `${this.baseURL}/v1/payments/stripe/products`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUserCreators(userId: string): Promise<Creator[]> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/creators`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async addRemoveCreatorFollow(
    userId: string,
    body: { creatorId: string; action: "add" | "remove" }
  ): Promise<{}> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/following`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    };
    return await this.handleFetch(endpoint, options);
  }

  async getFollowedCreators(
    userId: string,
    creatorIds: string[] = []
  ): Promise<Creator[]> {
    let endpoint = `${this.baseURL}/v1/users/${userId}/following`;
    if (creatorIds.length > 0) {
      endpoint += `?creatorIds=${creatorIds.join(",")}`;
    }
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async getFollowingLatestTracks(userId: string): Promise<Track[]> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/following-feed`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUserNotifications(
    userId: string,
    { sort, count, offset }: { sort: string; count: number; offset: number }
  ): Promise<UserNotificationResponse> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/notifications?sort=${sort}&count=${count}&offset=${offset}`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async updateUserNotification({
    userId,
    notificationId,
    body,
  }: {
    userId: string;
    notificationId: string;
    body: { read: boolean };
  }): Promise<UserNotification> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/notifications/${notificationId}`;
    const options = {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async deleteUserNotification({
    userId,
    notificationId,
  }: {
    userId: string;
    notificationId: string;
  }): Promise<{}> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/notifications/${notificationId}`;
    const options = {
      method: "DELETE",
    };
    return await this.handleFetch(endpoint, options);
  }

  async getCreatorAlbums(creatorId: string): Promise<Album[]> {
    const endpoint = `${this.baseURL}/v1/creators/${creatorId}/albums`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUserPlaylists(userId: string): Promise<Playlist[]> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/playlists`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async getPlaylist(playlistId: string): Promise<Playlist> {
    const endpoint = `${this.baseURL}/v1/playlists/${playlistId}`;
    const options = {
      method: "GET",
    };
    return await this.handleFetch(endpoint, options);
  }

  async createAlbum(albumDTO: FormData): Promise<Album> {
    const endpoint = `${this.baseURL}/v1/albums`;
    const options = {
      method: "POST",
      body: albumDTO,
    };
    return await this.handleFetch(endpoint, options);
  }

  async updateAlbum(id, albumDTO: FormData): Promise<Album> {
    const endpoint = `${this.baseURL}/v1/albums/${id}`;
    const options = {
      method: "PUT",
      body: albumDTO,
    };
    return await this.handleFetch(endpoint, options);
  }

  async createPlaylist(playlistDTO: any): Promise<Playlist> {
    const endpoint = `${this.baseURL}/v1/playlists`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(playlistDTO),
    };
    return await this.handleFetch(endpoint, options);
  }

  applyBlocklist(trackList) {
    // Blocklist: both andrews
    let blocklist = ["PLBz2BRzQq", "NoLz0E1lDn"];

    for (let i = trackList.length - 1; i >= 0; i--) {
      if (blocklist.includes(trackList[i].creators[0].id)) {
        trackList.splice(i, 1);
      }
    }
  }

  async updateCreator(id, creatorDTO: FormData): Promise<Creator> {
    const endpoint = `${this.baseURL}/v1/creators/${id}`;
    const options = {
      method: "PUT",
      body: creatorDTO,
    };
    return await this.handleFetch(endpoint, options);
  }

  async updateUser(id, userDTO: FormData): Promise<User> {
    const endpoint = `${this.baseURL}/v1/users/${id}`;
    const options = {
      method: "PUT",
      body: userDTO,
    };
    return await this.handleFetch(endpoint, options);
  }

  async getTrack(id): Promise<Track> {
    const endpoint = `${this.baseURL}/v1/tracks/${id}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async uploadTrack(trackDTO: FormData): Promise<Track> {
    const endpoint = `${this.baseURL}/v1/tracks`;
    const options = {
      method: "POST",
      body: trackDTO,
    };
    return await this.handleFetch(endpoint, options);
  }

  async updateTrack(trackId: string, trackDTO: FormData): Promise<Track> {
    const endpoint = `${this.baseURL}/v1/tracks/${trackId}`;
    const options = {
      method: "PUT",
      body: trackDTO,
    };
    return await this.handleFetch(endpoint, options);
  }

  async deleteTrack(trackId: string): Promise<{}> {
    const endpoint = `${this.baseURL}/v1/tracks/${trackId}`;
    const options = {
      method: "DELETE",
    };
    return await this.handleFetch(endpoint, options);
  }

  async updatePlaylist(playlistId, playlistDTO: object): Promise<Playlist> {
    const endpoint = `${this.baseURL}/v1/playlists/${playlistId}`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(playlistDTO),
    };
    return await this.handleFetch(endpoint, options);
  }

  async getLatestTracks(countSongs): Promise<Track[]> {
    const endpoint =
      `${this.baseURL}/v1/tracks?sort=-createdAt&count=` + countSongs;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getRandomTracks(countSongs = 15): Promise<Track[]> {
    const endpoint =
      `${this.baseURL}/v1/tracks?sort=random&count=` + countSongs;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async search(query: string, count: number = 10): Promise<SearchResult[]> {
    const endpoint = `${this.baseURL}/v1/search?query=${query}&count=${count}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getAlbum(id): Promise<Album> {
    const endpoint = `${this.baseURL}/v1/albums/${id}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getCreator(id): Promise<Creator> {
    const endpoint = `${this.baseURL}/v1/creators/${id}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUser(id): Promise<User> {
    const endpoint = `${this.baseURL}/v1/users/${id}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async createEvent(eventDTO: any): Promise<{}> {
    const endpoint = `${this.baseURL}/v1/data-analytics/event`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(eventDTO),
    };
    return await this.handleFetch(endpoint, options);
  }

  async runReport(
    report: "get_plays_for_tracks" | "get_listen_time_for_tracks",
    parameters: { trackIds: string[] }
  ): Promise<AnalyticsReport> {
    const endpoint = `${this.baseURL}/v1/data-analytics/report/run`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        report,
        parameters,
      }),
    };
    return await this.handleFetch(endpoint, options);
  }

  async searchTags(searchTerm: string, count: number): Promise<Tag[]> {
    const endpoint = `${this.baseURL}/v1/tags?q=${searchTerm}&count=${count}&sort=id`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getAllTags(count: number): Promise<Tag[]> {
    const endpoint = `${this.baseURL}/v1/tags?count=${count}&sort=id`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getLatestPodcasts(): Promise<Track[]> {
    const endpoint = `${this.baseURL}/v1/tracks?sort=-createdAt&count=15&tags=Podcast`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getTracksByTags(
    tag: string,
    count: number,
    sort: string,
    offset: number
  ): Promise<Track[]> {
    const endpoint = `${this.baseURL}/v1/tracks?sort=${sort}&count=${count}&tags=${tag}&offset=${offset}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return (await this.handleFetch(endpoint, options)) as Track[];
  }

  async createCreatorRequest(creatorReq: any): Promise<CreatorRequest> {
    const endpoint = `${this.baseURL}/v1/creator-requests`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(creatorReq),
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUserCreatorRequests(id): Promise<CreatorRequest[]> {
    const endpoint = `${this.baseURL}/v1/users/${id}/creator-requests`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUserSubscriptions(id): Promise<Subscription[]> {
    const endpoint = `${this.baseURL}/v1/users/${id}/subscriptions`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async likeTrack(trackId): Promise<TrackLike> {
    const endpoint = `${this.baseURL}/v1/tracks/${trackId}/likes`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    };
    return await this.handleFetch(endpoint, options);
  }

  async unLikeTrack(trackId): Promise<{}> {
    const endpoint = `${this.baseURL}/v1/tracks/${trackId}/likes`;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUserLikedTracks(userId): Promise<Track[]> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/tracks`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getTrackLikes(trackId): Promise<TrackLike[]> {
    const endpoint = `${this.baseURL}/v1/tracks/${trackId}/likes`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async listCreatorRequests(
    sort = "-createdAt",
    limit = 100,
    offset = 0
  ): Promise<CreatorRequest[]> {
    const endpoint = `${this.baseURL}/v1/creator-requests?sort=${sort}&limit=${limit}&offset=${offset}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async updateCreatorRequest(id, creatorRequestDTO): Promise<CreatorRequest> {
    const endpoint = `${this.baseURL}/v1/creator-requests/${id}`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(creatorRequestDTO),
    };
    return await this.handleFetch(endpoint, options);
  }

  async getUserDeletionRequest({ userId }): Promise<UserDeletionRequest | {}> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/deletion-request`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async createUserDeletionRequest({
    userId,
    userDeletionRequestDTO,
  }: {
    userId: string;
    userDeletionRequestDTO: { reason: string };
  }): Promise<UserDeletionRequest> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/deletion-request`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDeletionRequestDTO),
    };
    return await this.handleFetch(endpoint, options);
  }

  async deleteUserDeletionRequest({
    userId,
    deletionRequestId,
  }: {
    userId: string;
    deletionRequestId: string;
  }): Promise<{}> {
    const endpoint = `${this.baseURL}/v1/users/${userId}/deletion-request/${deletionRequestId}`;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }

  async getTopTracks({
    timeframe = "month",
    limit = 10,
    offset = 0,
    creatorIds = [],
  }: {
    timeframe?: string;
    limit?: number;
    offset?: number;
    creatorIds?: string[];
  }): Promise<Track[]> {
    let endpoint = `${this.baseURL}/v1/leaderboards/tracks?timeframe=${timeframe}&limit=${limit}&offset=${offset}`;
    if (creatorIds.length > 0) {
      endpoint += `&creatorIds=${creatorIds.join(",")}`;
    }
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return await this.handleFetch(endpoint, options);
  }
}

export default StageDiveAPI;
