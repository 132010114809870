import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import StageDiveAPI from "../api";
import { useUserStore } from "../store";

const stagedive = new StageDiveAPI();

const defaultStaleTime = 1000 * 60 * 5;

export const useGetNewReleases = () => {
  const count = 100;

  const extractDistinctTracksFromAlbums = (tracks: Track[]): Track[] => {
    const albumTrackMap: { [key: number]: Track } = {};

    tracks.forEach((track) => {
      const albumId = track.album.id;
      if (!albumTrackMap[albumId]) {
        albumTrackMap[albumId] = track;
      }
    });

    return Object.values(albumTrackMap).sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  };

  return useQuery({
    queryKey: ["getLatestTracks", count],
    queryFn: () => stagedive.getLatestTracks(count),
    select: (data) => extractDistinctTracksFromAlbums(data),
    staleTime: defaultStaleTime,
  });
};

export const useGetRandomTracks = () => {
  return useQuery({
    queryKey: ["getRandomTracks"],
    queryFn: () => stagedive.getRandomTracks(),
    staleTime: defaultStaleTime,
  });
};

export const useGetTracksByTags = ({
  tags,
  count = 20,
  offset = 0,
  sort = "random",
}: {
  tags: string;
  count?: number;
  offset?: number;
  sort?: string;
}) => {
  return useQuery({
    queryKey: ["getTracksByTags", tags, count, sort, offset],
    queryFn: () => stagedive.getTracksByTags(tags, count, sort, offset),
    staleTime: defaultStaleTime,
    enabled: !!tags,
  });
};

export const useGetMe = ({ enabled } = { enabled: true }) => {
  const { setUser } = useUserStore();
  return useQuery({
    queryKey: ["getMe"],
    queryFn: () =>
      stagedive.me().then((user) => {
        setUser(user);
        return user;
      }),
    staleTime: defaultStaleTime,
    enabled,
  });
};

export const useGetUserPlaylists = (userId) => {
  return useQuery({
    queryKey: ["getUserPlaylists", userId],
    queryFn: () => stagedive.getUserPlaylists(userId),
    staleTime: defaultStaleTime,
  });
};

export const useGetUserLikedTracks = (userId) => {
  return useQuery({
    queryKey: ["getUserLikedTracks", userId],
    queryFn: () => stagedive.getUserLikedTracks(userId),
    staleTime: defaultStaleTime,
  });
};

export const useGetFollowingLatestTracks = (userId) => {
  return useQuery({
    queryKey: ["getFollowingLatestTracks", userId],
    queryFn: () => stagedive.getFollowingLatestTracks(userId),
    staleTime: defaultStaleTime,
  });
};

export const useGetTrack = (trackId) => {
  return useQuery({
    queryKey: ["getTrack", trackId],
    queryFn: () => stagedive.getTrack(trackId),
    staleTime: defaultStaleTime,
  });
};

export const useGetAlbum = (albumId) => {
  return useQuery({
    queryKey: ["getAlbum", albumId],
    queryFn: () => stagedive.getAlbum(albumId),
    staleTime: defaultStaleTime,
    enabled: !!albumId,
  });
};

export function useGetUserNotifications({
  userId,
  sort = "-createdAt",
  count = 10,
}: {
  userId;
  sort?: string;
  count?: number;
}) {
  return useInfiniteQuery({
    queryKey: ["getUserNotifications", userId, sort, count],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await stagedive.getUserNotifications(userId, {
        sort,
        count,
        offset: pageParam,
      });
      return response;
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalFetched = allPages.reduce(
        (total, page) => total + page.items.length,
        0
      );
      if (lastPage.items.length < count) {
        return undefined; // No more pages
      }
      return totalFetched; // Next offset
    },
    initialPageParam: 0,
    staleTime: defaultStaleTime,
  });
}

export const useUpdateUserNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<
    UserNotification,
    Error,
    { userId: string; notificationId: string; body: { read: boolean } }
  >({
    mutationFn: ({ userId, notificationId, body }) =>
      stagedive.updateUserNotification({ userId, notificationId, body }),
    onSuccess: (notification) => {
      queryClient.setQueryData<
        InfiniteData<UserNotificationResponse> | undefined
      >(
        ["getUserNotifications", notification.userId, "-createdAt", 10],
        (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            pages: oldData.pages.map((page) => ({
              ...page,
              items: page.items.map((item) =>
                item.id === notification.id ? notification : item
              ),
            })),
          };
        }
      );
    },
  });
};

export const useDeleteUserNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<any, Error, { userId: string; notificationId: string }>({
    mutationFn: ({ userId, notificationId }) =>
      stagedive.deleteUserNotification({ userId, notificationId }),
    onSuccess: (notification, { userId, notificationId }) => {
      queryClient.setQueryData<
        InfiniteData<UserNotificationResponse> | undefined
      >(["getUserNotifications", userId, "-createdAt", 10], (oldData) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          pages: oldData.pages
            .map((page) => ({
              ...page,
              items: page.items.filter((item) => item.id !== notificationId),
            }))
            .filter((page) => page.items.length > 0), // Remove empty pages
        };
      });
    },
  });
};

export const useGetUserDeletionRequest = (userId) => {
  return useQuery({
    queryKey: ["getUserDeletionRequest", userId],
    queryFn: () => stagedive.getUserDeletionRequest({ userId }),
    select: (data) => (Object.keys(data).length === 0 ? null : data),
    staleTime: defaultStaleTime,
    enabled: !!userId,
  });
};

export const useCreateUserDeletionRequest = () => {
  return useMutation<
    any,
    Error,
    { userId: string; userDeletionRequestDTO: { reason: string } }
  >({
    mutationFn: (data) => stagedive.createUserDeletionRequest(data),
  });
};

export const useDeleteUserDeletionRequest = () => {
  return useMutation<any, Error, { userId: string; deletionRequestId: string }>(
    {
      mutationFn: (data) => stagedive.deleteUserDeletionRequest(data),
    }
  );
};

export function useGetTopTracks({
  timeframe = "month",
  limit = 10,
  offset = 0,
  creatorIds = [],
}: {
  timeframe?: string;
  limit?: number;
  offset?: number;
  creatorIds?: string[];
}) {
  return useQuery({
    queryKey: ["getTopTracks", timeframe, limit, offset, creatorIds],
    queryFn: async ({ pageParam = 0 }) => {
      const response = await stagedive.getTopTracks({
        timeframe,
        limit,
        offset,
        creatorIds,
      });
      return response;
    },
    staleTime: defaultStaleTime,
  });
}
