import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { getWebsiteDomain } from "config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  creator;
  isFollowed: boolean;
  showFollowingButton: boolean;
  followCreator: () => void;
};

const CreatorBanner = ({
  creator,
  isFollowed,
  followCreator,
  showFollowingButton,
}: Props) => {
  const socials = {
    facebook: {
      icon: faFacebook,
    },
    instagram: {
      icon: faInstagram,
    },
    tiktok: {
      icon: faTiktok,
    },
    twitter: {
      icon: faTwitter,
    },
    youtube: {
      icon: faYoutube,
    },
  };

  const copyURL = () => {
    try {
      const copyText = `${getWebsiteDomain()}/creator/${creator.id}`;
      navigator.clipboard.writeText(copyText);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex flex-col bg-zinc-900 p-5 shadow">
      <div className="max-w-screen-lg w-full mx-auto">
        <div className="flex flex-wrap flex-nowrap items-center bg-zinc-900 rounded-lg">
          {/* creator's Profile Image */}
          <div className="flex justify-center md:w-64 lg:justify-end mb-0">
            <img
              src={creator.profileImage ?? "/profile.webp"}
              alt="creator Profile"
              className="rounded-full w-32 h-32 object-cover"
            />
          </div>

          {/* creator's Info */}
          <div className="w-full w-3/4 pl-9 lg:justify-start">
            <div className="flex flex-col">
              <h1 className="text-2xl font-bold mt-4">{creator.name}</h1>

              <p className="my-2">{creator.bio}</p>
            </div>

            {/* Creator's Following */}
            <div className="flex mt-2 mb-4">
              <p className="font-bold">{`${creator.followerCount} Followers`}</p>
            </div>

            {/* Social Links */}
            <div className="flex space-x-3 mt-2">
              <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-3">
                {Object.keys(creator.socials).map((socialName) => {
                  if (creator.socials[socialName]) {
                    return (
                      <div key={socialName}>
                        <FontAwesomeIcon
                          icon={socials[socialName].icon}
                          className="text-white"
                        />
                        <a
                          href={
                            creator.socials[socialName].startsWith(
                              "https://"
                            ) ||
                            creator.socials[socialName].startsWith("http://")
                              ? creator.socials[socialName]
                              : `https://${creator.socials[socialName]}`
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          data-testid={socialName}
                          className="text-white hover:text-zinc-600 capitalize ml-1"
                        >
                          {socialName}
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Options */}
        <div id="profile-options" className="flex justify-end">
          {showFollowingButton && (
            <button
              className="btn btn-sm invert normal-case cursor-pointer ml-3 mt-4"
              onClick={followCreator}
            >
              {isFollowed ? "Unfollow" : "Follow"}
            </button>
          )}

          <button
            onClick={copyURL}
            className="btn btn-sm invert normal-case cursor-pointer ml-3 mt-4"
          >
            Share Link
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="h-4 w-4 flex-no-shrink fill-current"
            >
              <path d="M307 34.8c-11.5 5.1-19 16.6-19 29.2v64H176C78.8 128 0 206.8 0 304C0 417.3 81.5 467.9 100.2 478.1c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-7.5-4.3-14.4-9.8-19.5C108.8 431.9 96 414.4 96 384c0-53 43-96 96-96h96v64c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatorBanner;
