import { getWebsiteDomain } from "config";
import { usePlaylistPlayer } from "context/playlistPlayer";
import placeholder from "../../assets/images/stagedive-logo.png";

const Album = ({
  album,
  onAlbumEdit,
  onTrackEdit,
  onTrackDelete,
  editable = false,
}) => {
  const { clearAndLoadNewPlaylist } = usePlaylistPlayer();
  const copyTrackURL = (trackID) => {
    try {
      const copyText = `${getWebsiteDomain()}/track/${trackID}`;
      navigator.clipboard.writeText(copyText);
      alert("Link copied to clipboard!");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="bg-neutral-900 rounded-md mb-6 p-3 md:p-6 overflow-x-auto">
      <div className="flex">
        <img
          className="flex-none w-14 h-14 mr-2 md:mr-6 object-contain"
          alt="album cover"
          src={album.coverImageUrl ? album.coverImageUrl : placeholder}
        />
        <h3 className="text-1xl font-bold inline-flex items-center">
          {album.name}
          <span
            className="material-symbols-rounded play-playlist cursor-pointer text-2xl ml-2"
            onClick={() => clearAndLoadNewPlaylist(album.tracks, 0)}
          >
            play_circle
          </span>
        </h3>
        {editable && (
          <button
            className="btn ml-auto normal-case"
            onClick={() => onAlbumEdit(album)}
          >
            Edit
          </button>
        )}
      </div>
      <div className="mt-4">
        <div className="flex font-bold mb-3 pl-3">
          <div className="w-12">#</div>
          <div className="flex-1">Track</div>
          <div className="w-12"></div>
          {editable && <div className="w-24"></div>}
        </div>
        {album.tracks.length > 0 ? (
          album.tracks.map((track, index) => (
            <div
              className="flex items-center hover:bg-neutral-800 mb-1 py-3 md:py-0 pl-3 md:min-h-8 rounded-sm"
              key={track.id}
            >
              <div className="w-12">{index + 1}</div>
              <div className="flex-1">{track.name}</div>
              <div className="">
                <span
                  className="material-symbols-rounded play-playlist cursor-pointer"
                  onClick={() => clearAndLoadNewPlaylist(album.tracks, index)}
                >
                  play_circle
                </span>
              </div>
              {editable && (
                <div>
                  {/* Desktop Options */}
                  <div className="hidden md:flex">
                    <button
                      className="btn btn-s normal-case m-1 hover:bg-neutral-700"
                      onClick={() => copyTrackURL(track.id)}
                    >
                      Share
                    </button>
                    <button
                      className="btn btn-s normal-case m-1 hover:bg-neutral-700"
                      onClick={() => onTrackEdit({ ...track, album })}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-s normal-case m-1 hover:bg-neutral-700"
                      onClick={(e) => onTrackDelete(e, track)}
                    >
                      Delete
                    </button>
                  </div>

                  {/* Mobile Options */}
                  <div className="md:hidden">
                    {/* Open the modal using document.getElementById('ID').showModal() method */}
                    <button
                      className=""
                      onClick={() =>
                        document
                          .getElementById(`track_options_modal_${track.id}`)
                          // @ts-ignore
                          ?.showModal()
                      }
                    >
                      <span className="material-symbols-rounded align-middle py-0 px-2">
                        settings
                      </span>
                    </button>
                    <dialog
                      id={"track_options_modal_" + track.id}
                      className="modal modal-bottom sm:modal-middle"
                    >
                      <div className="modal-box">
                        <p className="text-sm">
                          {album.name}
                          {album.artist ? " - " + album.artist : ""}
                        </p>
                        <h4 className="font-bold text-lg">{track.name}</h4>
                        <hr className="mt-4 border-white" />
                        <div className="modal-action justify-start">
                          <form method="dialog" className="w-full">
                            {/* if there is a button in form, it will close the modal */}
                            <button
                              className="flex normal-case my-2 p-2 w-full rounded text-left hover:bg-neutral-600/50"
                              onClick={() => copyTrackURL(track.id)}
                            >
                              <span className="material-symbols-rounded p-0 pr-2">
                                ios_share
                              </span>
                              Share Track
                            </button>
                            <button
                              className="flex normal-case my-2 p-2 w-full rounded text-left hover:bg-neutral-600/50"
                              onClick={() => onTrackEdit({ ...track, album })}
                            >
                              <span className="material-symbols-rounded p-0 pr-2">
                                edit
                              </span>
                              Edit Track
                            </button>
                            <button
                              className="flex normal-case my-2 p-2 w-full rounded text-left hover:bg-neutral-600/50"
                              onClick={(e) => onTrackDelete(e, track)}
                            >
                              <span className="material-symbols-rounded p-0 pr-2">
                                delete
                              </span>
                              Delete Track
                            </button>
                            <button className="flex normal-case my-2 p-2 w-full rounded text-left hover:bg-neutral-600/50">
                              <span className="material-symbols-rounded p-0 pr-2">
                                close
                              </span>
                              Close Menu
                            </button>
                          </form>
                        </div>
                      </div>
                      <form method="dialog" className="modal-backdrop">
                        <button>close</button>
                      </form>
                    </dialog>
                  </div>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="text-center">No tracks added yet</div>
        )}
      </div>
    </div>
  );
};

export default Album;
