import { useContext, useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useUserStore } from "store";
import { ApiContext } from "../../../../../../context";
import "./index.css";

export default function Home() {
  const stagedive = useContext(ApiContext);
  const { user } = useUserStore();

  //   const [creator, setCreator] = useState({} as any);
  //   const [albums, setAlbums] = useState([]);
  const [tracks, setTracks] = useState([] as any);
  const [isLoading, setIsLoading] = useState(true);
  const [listenTime, setListenTime] = useState("" as any);
  const [playsCount, setPlaysCount] = useState("" as any);

  // Grab user's tracks from API
  useEffect(() => {
    const getTracks = async () => {
      setIsLoading(true);
      try {
        if (user) {
          const creators = await stagedive.getUserCreators(user.id);
          if (creators.length > 0) {
            const creator = creators[0];
            const albums = await stagedive.getCreatorAlbums(creator.id);
            //   setCreator(creator);
            //   setAlbums(albums);
            // console.log('albums: ', albums);
            let creatorTracks: any[] = [];
            albums.forEach(function (album) {
              album.tracks.forEach(function (track) {
                creatorTracks.push(track);
              });
            });
            setTracks(creatorTracks);
            // console.log('tracks: ', creatorTracks);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    getTracks();
  }, [user, stagedive]);

  // Once tracks are loaded, run the report
  useEffect(() => {
    const getTimeListenedAnalytics = async () => {
      if (tracks && tracks.length) {
        let trackIds = tracks.map((track) => track.id);
        // console.log('trackIds: ', trackIds);
        let api_report = await stagedive.runReport(
          "get_listen_time_for_tracks",
          {
            trackIds: trackIds,
          }
        );
        // console.log('timelistened_api_report: ', api_report);
        let report = api_report.map(function (item) {
          return [item.trackName, Math.ceil(item.secondsListened / 60)];
        });
        // console.log('report: ', report);
        let data = [["Track", "Time Listened (minutes)"]].concat(report);
        setListenTime(data);
      }
    };

    const getTrackPlaysAnalytics = async () => {
      if (tracks && tracks.length) {
        let trackIds = tracks.map((track) => track.id);
        // console.log('trackIds: ', trackIds);
        let api_report = await stagedive.runReport("get_plays_for_tracks", {
          trackIds: trackIds,
        });
        // console.log('trackplays_api_report: ', api_report);
        let report = api_report.map(function (item) {
          return [item.trackName, item.plays];
        });
        // console.log('report: ', report);
        let data = [["Track", "Plays"]].concat(report);
        setPlaysCount(data);
      }
    };

    getTimeListenedAnalytics();
    getTrackPlaysAnalytics();
  }, [tracks, stagedive]);

  if (isLoading) return null;
  return (
    <section className="m-3">
      <h2 className="text-2xl">Analytics</h2>
      <p>
        Welcome to your creator analytics! We will continuously be updating and
        improving the way you track your audience engagement.
      </p>

      {!listenTime && !playsCount && (
        <p>
          Analytic charts will appear here once uploaded tracks have been
          played.
        </p>
      )}
      <div
        id="analytics-tables"
        className="flex flex-col gap-3 items-center justify-center sm:flex-row mt-3 text-white"
      >
        {listenTime && (
          <Chart
            className="chart"
            chartType="Table"
            // DATA FORMAT:
            // data={[
            // 	["Track Name", "Time Listened"],
            // 	['abdefg', 20],
            // 	['hijklm', 30]
            // ]}
            data={listenTime}
          />
        )}

        <br />

        {playsCount && (
          <Chart
            className="chart"
            chartType="Table"
            // DATA FORMAT:
            // data={[
            // 	["Track ID", "Time Plays"],
            // 	['abdefg', 20],
            // 	['hijklm', 30]
            // ]}
            data={playsCount}
          />
        )}
      </div>
    </section>
  );
}
