import { useState } from "react";
import { Link } from "react-router-dom";

import "./About.css";

export default function About() {
  const [activeView, setActiveView] = useState("creator");

  return (
    <div id="about-container">
      <section id="hero">
        <div className="sd-backdrop-filter">
          <h1 id="page-heading">TAKE THE DIVE</h1>
        </div>
      </section>

      <div className="content-column">
        <h1>About StageDive</h1>

        <section id="platform">
          <h2>StageDive Platform</h2>
          <p>
            StageDive is <em>your</em> platform. We exist to help creators reach
            a wider audience and make a living doing what they love. We exist to
            help fans connect with their favorite artists and enjoy content -
            knowing that their support has a direct impact on the creators.
          </p>
          <p>
            Other platforms require a large following and millions of streams to
            make enough ad revenue to provide creators a living. We only require
            engagement from the fans, and a portion of their subscription goes
            directly to the creators.
          </p>
          <p>
            Our goal is to empower every creative and every listener by offering
            an honest platform where everyone can benefit.
          </p>
        </section>

        <div className="view-card">
          <div className="view-select">
            <button onClick={() => setActiveView("creator")}>
              For Creators
            </button>
            <button onClick={() => setActiveView("listener")}>
              For Listeners
            </button>
          </div>

          <section
            id="creators"
            style={{
              display: activeView === "creator" ? "block" : "none",
            }}
            className={`transition-opacity duration-300 ease-in-out absolute w-full ${activeView === "creators" ? "opacity-100 visible relative" : "opacity-0 invisible"}`}
          >
            <h2>Creators</h2>

            <h3>GET PAID WITHOUT ADS - YES, REALLY.</h3>
            <p>
              StageDive does not play ads among our creators' content. We
              provide a subscription-based model where listeners can discover
              new music, comedy, and commentary for a fixed monthly fee. When
              creators attract a listener's attention, they earn a portion of
              that listener's subscription. Doesn't that just make the most
              sense for everyone involved? We think so.
            </p>
            <p>
              The more listeners you attract, the more subscription revenue you
              earn. It's that simple.
            </p>

            <h3>REACH YOUR AUDIENCE</h3>
            <p>
              Put your voice out there, and see who is interested. You have a
              unique perspective, and the world needs to hear from you.
            </p>

            <h3>HONE YOUR CRAFT</h3>
            <p>
              StageDive provides unique analytics to help you understand what
              they love about your music. Refine your sound to build a deeper
              connection with your audience.
            </p>

            <h3>REMOVING BARRIERS</h3>
            <p>
              We partner with you from the start to help you build the reach you
              are looking for. Your job is to do what you love - Share your
              creative works, be it music, comedy or commentary.
            </p>
            <p>
              Through the evolution of the music and content creation
              industries, business norms which benefit small groups of industry
              giants have formed, crowding out the voices of independent
              creators and their loyal followers.
            </p>
            <p>
              StageDive exists to disrupt these norms, and to empower the voices
              of the creators and listeners - the true stakeholders of content
              creation.
            </p>

            <h3>WHY CHOOSE US?</h3>
            <p>
              Because your voice matters to us, and our mission is to amplify
              you.
            </p>

            <br />
            <h3 className="center">Ready to take the Dive?</h3>
            <Link to="/profile" className="button center">
              Get Started!
            </Link>
          </section>

          <section
            id="listeners"
            style={{
              display: activeView === "listener" ? "block" : "none",
            }}
            className={`transition-opacity duration-300 ease-in-out absolute w-full ${activeView === "listener" ? "opacity-100 visible relative" : "opacity-0 invisible"}`}
          >
            <h2>Listeners</h2>

            <h3>STREAM CONTENT - SUPPORT THE CREATORS</h3>
            <p>
              StageDive aims to put creators first, meaning your subscription
              will directly benefit the creators you love.
            </p>

            <h3>JAM OUT, UNINTERRUPTED</h3>
            <p>You hate ads, we hate ads. The solution? No ads.</p>

            <h3>TIP CREATORS DIRECTLY</h3>
            <p>
              StageDive wants our creators to thrive. That's why we offer
              tipping options in-app. Listen to content and tip your favorite
              artists, all in one place.
            </p>

            <h3>WHY CHOOSE US?</h3>
            <p>
              We aim to do things differently. No ads. Profit sharing with
              creators. Propelling new artists. Support your favorite creators
              where they want to be.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}
