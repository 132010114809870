import { useQueryClient } from "@tanstack/react-query";
import { useErrorHandler } from "context/ErrorHandler";
import { useContext, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useUserStore } from "store";
import Album from "../../components/Album";
import AuthModal from "../../components/AuthModal";
import CreatorBanner from "../../components/CreatorBanner";
import SubscriptionModal from "../../components/SubscriptionModal";
import { ApiContext } from "../../context";

function CreatorView() {
  const { user } = useUserStore();
  const { handleError } = useErrorHandler();
  const { id } = useParams();

  const stagedive = useContext(ApiContext);
  const queryClient = useQueryClient();

  const [creatorAlbums, setCreatorAlbums] = useState<Album[]>([]);
  const [creator, setCreator] = useState({}) as any;
  const [isFollowing, setIsFollowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getCreator = async (creatorId) => {
    const creator = await stagedive.getCreator(creatorId);
    return creator;
  };

  useEffect(() => {
    //Gets the data for the passed in Creator ID
    const getCreatorData = async (creatorId) => {
      setIsLoading(true);
      try {
        const creator = await getCreator(creatorId);
        const creatorAlbums = await stagedive.getCreatorAlbums(creatorId);
        setCreator(creator);
        setCreatorAlbums(creatorAlbums);

        if (user) {
          const followedCreators = await stagedive.getFollowedCreators(
            user.id,
            [creator.id]
          );
          const isFollowing = followedCreators.length > 0;
          setIsFollowing(isFollowing);
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };

    getCreatorData(id);
  }, [id, stagedive, user]);

  // Modals
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [disableSubscriptionModal, setDisableSubscriptionModal] =
    useState(false);
  const [modalError, setModalError] = useState(null as any);
  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const toggleSubscriptionModal = () => {
    setOpenSubscriptionModal((open) => !open);
  };
  const [disableAuthModal, setDisableAuthModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const toggleAuthModal = () => {
    setOpenAuthModal((open) => !open);
  };

  const followCreator = async () => {
    try {
      if (!user) return;

      if (isFollowing) {
        await stagedive.addRemoveCreatorFollow(user.id, {
          creatorId: creator.id,
          action: "remove",
        });
        setIsFollowing(false);
      } else {
        await stagedive.addRemoveCreatorFollow(user.id, {
          creatorId: creator.id,
          action: "add",
        });
        setIsFollowing(true);
      }
      const updatedCreator = await getCreator(creator.id);
      queryClient.invalidateQueries({
        queryKey: ["getFollowingLatestTracks", user.id],
      });
      setCreator(updatedCreator);
    } catch (error: any) {
      handleError(error);
    }
  };

  if (isLoading) {
    return (
      <span className="loading loading-bars loading-lg flex h-2/5 m-auto"></span>
    );
  } else
    return (
      <>
        <CreatorBanner
          creator={creator}
          showFollowingButton={true}
          isFollowed={isFollowing}
          followCreator={followCreator}
        />

        {/* Any attempt to interact will trigger appropriate modals based on auth + subscription states */}
        <div
          className="creator-view-container"
          onClick={() => {
            if (!user) {
              setOpenAuthModal(true);
              setSearchParams(
                new URLSearchParams({ redirectToPath: location.pathname })
              );
            } else {
              setOpenSubscriptionModal(true);
            }
          }}
        >
          <section className="p-4 md:p-10">
            <h2 className="text-lg mb-2 ml-1">Albums</h2>
            {creatorAlbums.map((album, index) => (
              <Album
                album={album}
                onAlbumEdit={() => {}}
                onTrackEdit={() => {}}
                onTrackDelete={() => {}}
                editable={false}
                key={index}
              />
            ))}
          </section>
        </div>

        <SubscriptionModal
          open={openSubscriptionModal}
          handleToggle={toggleSubscriptionModal}
          isLoading={disableSubscriptionModal}
          error={modalError}
        ></SubscriptionModal>

        <AuthModal
          open={openAuthModal}
          handleToggle={toggleAuthModal}
          isLoading={disableAuthModal}
          error={modalError}
        ></AuthModal>
      </>
    );
}

export default CreatorView;
